<template>
    <section class="property-section">
        <div class="auto-container">
            <el-steps :active="active" class="mb-5">
                <el-step
                    title="حدد الأيام"
                    ref="step1"
                    :status="
                        error == 1
                            ? 'error'
                            : active == 1
                            ? 'finish'
                            : 'success'
                    "
                    icon="el-icon-date"
                ></el-step>
                <el-step
                    title="تأكيد الطلب"
                    :status="
                        error == 2
                            ? 'error'
                            : active == 2
                            ? 'finish'
                            : active > 2
                            ? 'success'
                            : 'wait'
                    "
                    icon="el-icon-s-promotion"
                ></el-step>
            </el-steps>

            <!-- First  step  -->
            <div :class="{ 'd-none': active !== 1 }" class="row">
                <div class="text-center col-md-4">
                    <div class="d-inline-block">
                        <input ref="date" v-model="dates" type="text" />
                    </div>
                </div>

                <div class="text-center col-md-4">
                    <section>
                        <span>فترة الدخول</span>
                        <div class="row">
                            <div
                                v-for="(period, index) in periods"
                                :key="index"
                                class="form-check form-check-inline mt-2 col-12"
                            >
                                <label class="form-check-label">
                                    <input
                                        :disabled="
                                            fromDisabledPeriods.includes(
                                                index + 1
                                            )
                                        "
                                        class="form-check-input"
                                        type="radio"
                                        name="from_period"
                                        v-model="from_period"
                                        :value="index + 1"
                                    />
                                    {{ period.note }} ({{ period.price }} ريال)
                                </label>
                            </div>
                        </div>
                    </section>

                    <section class="mt-5">
                        <span>فترة المغادرة</span>
                        <div class="row">
                            <div
                                v-for="(period, index) in periods"
                                :key="index"
                                class="form-check form-check-inline mt-2 col-12"
                            >
                                <label class="form-check-label">
                                    <input
                                        :disabled="
                                            toDisabledPeriods.includes(
                                                index + 1
                                            )
                                        "
                                        class="form-check-input"
                                        type="radio"
                                        name="to_period"
                                        v-model="to_period"
                                        :value="index + 1"
                                    />
                                    {{ period.note }} ({{ period.price }} ريال)
                                </label>
                            </div>
                        </div>
                    </section>
                </div>

                <div class="text-center col-md-4">
                    <span>إضافة حفلة</span>

                    <div class="form-check form-check-inline mt-2 col-12">
                        <label class="form-check-label">
                            <input
                                class="form-check-input"
                                type="radio"
                                value="birthday"
                                name="party"
                                v-model="party"
                            />
                            حفلة عيد ميلاد ({{ property.birthday_party_price }}
                            ريال)
                        </label>
                    </div>

                    <div class="form-check form-check-inline mt-2 col-12">
                        <label class="form-check-label">
                            <input
                                class="form-check-input"
                                type="radio"
                                value="wedding"
                                name="party"
                                v-model="party"
                            />
                            حفلة زواج ({{ property.wedding_party_price }} ريال)
                        </label>
                    </div>

                    <div class="form-check form-check-inline mt-2 col-12">
                        <label class="form-check-label">
                            <input
                                class="form-check-input"
                                type="radio"
                                value="other"
                                name="party"
                                v-model="party"
                            />
                            أخرى ({{ property.other_party_price }} ريال)
                        </label>
                    </div>

                    <button
                        @click="checkPrice()"
                        type="button"
                        class="theme-btn btn-style-one mt-3 w-100"
                    >
                        <i class="la la-check"></i> حساب السعر
                    </button>
                </div>
            </div>

            <!-- Second step -->
            <div class="row" :class="{ 'd-none': active !== 2 }">
                <div class="grow-1">
                    <button
                        type="button"
                        class="btn btn-secondary"
                        @click="returnToFirst()"
                    >
                        <i class="la la-share"></i> رجوع
                    </button>
                </div>
                <div>
                    <h2>التكلفة النهائية : {{ totalPrice }} ريال</h2>

                    <button
                        @click="submit()"
                        class="theme-btn btn-style-one w-100 mt-5"
                    >
                        <h4 class="d-inline">
                            <i class="la la-paper-plane"></i> تأكيد الطلب
                        </h4>
                    </button>
                </div>
                <div class="grow-1"></div>
            </div>

            <!-- Finish-->
            <div class="row text-center" :class="{ 'd-none': active !== 3 }">
                <div class="grow-1"></div>
                <div class="text-success mt-5">
                    <h1>
                        <i class="la la-check-circle"></i>
                    </h1>
                    <h3 class="mt-5">
                        تم إرسال الطلب بنجاح ويرجى تأكيد الدفع لإتمام الحجز !
                    </h3>
                    <iframe
                        class="payment-iframe"
                        v-if="paymentUrl"
                        :src="paymentUrl"
                    ></iframe>
                </div>
                <div class="grow-1"></div>
            </div>
        </div>
    </section>
</template>

<script>
import { ElSteps, ElStep } from "element-plus";
import flatpickr from "flatpickr";
import { Arabic } from "flatpickr/dist/l10n/ar.js";
import moment from "moment";
import { debounce, range } from "lodash";
import VueIframe from "vue-iframes";

export default {
    name: "rent-property",
    components: { "el-steps": ElSteps, "el-step": ElStep },
    data() {
        return {
            active: 1,
            dateObj: null,
            error: 0,
            property: {},
            totalPrice: 0,
            from_period: null,
            to_period: null,
            dates: "",
            party: null,
            needAttentionDates: {},
            fromDisabledPeriods: [],
            toDisabledPeriods: [],
            paymentUrl: null,
        };
    },
    beforeRouteEnter(to, from, next) {
        next(($this) => {
            $this.reInitialize();
        });
    },
    methods: {
        reInitialize() {
            this.active = 1;
            this.dateObj = null;
            this.error = 0;
            this.property = {};
            this.totalPrice = 0;
            this.from_period = null;
            this.to_period = null;
            this.dates = "";
            this.party = null;
            this.needAttentionDates = {};
            this.fromDisabledPeriods = [];
            this.toDisabledPeriods = [];

            this.$axios
                .get(
                    "/property/" +
                        this.$route.params.id +
                        "?with_reservations=true"
                )
                .then((res) => {
                    this.property = res.data.property;
                })
                .then(() => {
                    this.initCalender();
                });
        },
        getReservations(month = "today") {
            this.$axios
                .get(
                    "/property/" +
                        this.$route.params.id +
                        "/reservations?month=" +
                        month
                )
                .then((res) => {
                    this.property.reservations = res.data.reservations;

                    this.setCalenderDisabled();
                });
        },
        initCalender() {
            this.dateObj = flatpickr(this.$refs.date, {
                inline: true,
                locale: Arabic,
                minDate: Date.now(),
                mode: "range",
                dateFormat: "Y-m-d",
                disable: this.formatCalenderDisabled(),
                onMonthChange: debounce((unneeded, dates, instance) => {
                    this.getReservations(
                        instance.currentYear + "-" + (instance.currentMonth + 1)
                    );
                }),
                onYearChange: debounce((unneeded, dates, instance) => {
                    this.getReservations(
                        instance.currentYear + "-" + (instance.currentMonth + 1)
                    );
                }),
                onChange: (unneeded, dates) => {
                    this.fromDisabledPeriods = [];
                    this.toDisabledPeriods = [];
                    this.from_period = null;
                    this.to_period = null;

                    let datesArray = dates.split(" - ");

                    if (datesArray[0] in this.needAttentionDates) {
                        this.fromDisabledPeriods = this.needAttentionDates[
                            datesArray[0]
                        ];

                        if (datesArray.length == 1) {
                            this.toDisabledPeriods = this.needAttentionDates[
                                datesArray[0]
                            ];
                        }
                    }

                    if (datesArray[1] in this.needAttentionDates) {
                        this.toDisabledPeriods = this.needAttentionDates[
                            datesArray[1]
                        ];
                    }
                },
                onDayCreate: (unneeded, unneeded1, unneeded2, dayElement) => {
                    if (
                        moment(dayElement.dateObj).format("YYYY-MM-DD") in
                        this.needAttentionDates
                    ) {
                        dayElement.innerHTML +=
                            "<span class='partially-disabled'></span>";
                    }
                },
            });
        },
        setCalenderDisabled() {
            this.dateObj.set("disable", this.formatCalenderDisabled());
        },
        formatCalenderDisabled() {
            let max_period = this.periods.length;

            let disabled = [];

            this.property.reservations.forEach((reservation) => {
                if (
                    reservation.from_period == 1 &&
                    reservation.from_day != reservation.to_day
                ) {
                    var from = reservation.from_day;
                } else {
                    let maxLimitPeriod =
                        reservation.from_day == reservation.to_day
                            ? parseInt(reservation.to_period)
                            : max_period;

                    let reservedPeriods = range(
                        reservation.from_period,
                        maxLimitPeriod + 1
                    );

                    if (reservation.from_day in this.needAttentionDates) {
                        reservedPeriods = [
                            ...new Set(
                                reservedPeriods.concat(
                                    this.needAttentionDates[
                                        reservation.from_day
                                    ]
                                )
                            ),
                        ];

                        if (reservedPeriods.length >= max_period) {
                            var from = reservation.from_day;

                            if (reservation.from_day == reservation.to_day) {
                                var to = reservation.to_day;
                            }

                            delete this.needAttentionDates[
                                reservation.from_day
                            ];
                        } else {
                            this.needAttentionDates[
                                reservation.from_day
                            ] = reservedPeriods;
                        }
                    } else {
                        if (reservation.from_day != reservation.to_day) {
                            var from = moment(reservation.from_day)
                                .add(1, "day")
                                .format("YYYY-MM-DD");
                        }

                        this.needAttentionDates[
                            reservation.from_day
                        ] = reservedPeriods;
                    }
                }

                if (
                    reservation.to_period == max_period &&
                    reservation.from_day != reservation.to_day
                ) {
                    var to = reservation.to_day;
                } else if (reservation.from_day != reservation.to_day) {
                    let minLimitPeriod =
                        reservation.from_day == reservation.to_day
                            ? parseInt(reservation.from_period)
                            : 1;
                    let reservedPeriods = range(
                        reservation.to_period,
                        minLimitPeriod - 1,
                        -1
                    );

                    if (reservation.to_day in this.needAttentionDates) {
                        reservedPeriods = [
                            ...new Set(
                                reservedPeriods.concat(
                                    this.needAttentionDates[reservation.to_day]
                                )
                            ),
                        ];

                        if (reservedPeriods.length >= max_period) {
                            var to = reservation.to_day;

                            delete this.needAttentionDates[reservation.to_day];
                        } else {
                            this.needAttentionDates[
                                reservation.to_day
                            ] = reservedPeriods;
                        }
                    } else {
                        if (reservation.from_day != reservation.to_day) {
                            var to = moment(reservation.to_day)
                                .subtract(1, "day")
                                .format("YYYY-MM-DD");
                        }

                        this.needAttentionDates[
                            reservation.to_day
                        ] = reservedPeriods;
                    }
                }

                if (from && to) {
                    disabled.push({
                        from,
                        to,
                    });
                }
            });

            return disabled;
        },
        returnToFirst() {
            this.reInitialize();
        },
        checkPrice() {
            if (this.dates.length < 10 || this.dates.split(" - ").length < 1) {
                this.error = 1;
                this.$noty.in("topCenter").error("يجب تحديد المدة");
            } else {
                this.error = 0;
                let dates = this.$refs.date.value.split(" - ");

                this.$axios
                    .post("/calculate-price", {
                        property_id: this.property.id,
                        from_day: dates[0],
                        to_day: dates[1] ?? dates[0],
                        from_period: this.from_period,
                        to_period: this.to_period,
                        party: this.party,
                    })
                    .then((res) => {
                        this.totalPrice = res.data.price;

                        this.active = 2;
                    });
            }
        },
        submit() {
            let dates = this.$refs.date.value.split(" - ");
            this.$axios
                .post("/my/submit-order", {
                    property_id: this.property.id,
                    from_day: dates[0],
                    to_day: dates[1] ?? dates[0],
                    from_period: this.from_period,
                    to_period: this.to_period,
                    party: this.party,
                })
                .then((res) => {
                    this.remainingSeconds = 5;
                    this.paymentUrl = res.data.url;
                    this.active = 3;
                });
        },
        reset() {
            this.active = 1;
        },
    },
    computed: {
        periods() {
            let periods = [];

            periods.push({
                price: this.property.price_1,
                note: this.property.note_1,
            });

            if (this.property.price_2) {
                periods.push({
                    price: this.property.price_2,
                    note: this.property.note_2,
                });
            }

            if (this.property.price_3) {
                periods.push({
                    price: this.property.price_3,
                    note: this.property.note_3,
                });
            }

            return periods;
        },
    },
};
</script>
<style>
@import "../../../../node_modules/element-plus/lib/theme-chalk/index.css";
@import "../../../../node_modules/flatpickr/dist/flatpickr.min.css";

.el-step__main {
    text-align: right !important;
}

.grow-1 {
    flex-grow: 1;
}

.grow-2 {
    flex-grow: 2;
}

.partially-disabled {
    position: absolute;
    width: 3px;
    height: 3px;
    border-radius: 150px;
    bottom: 3px;
    left: calc(50% - 1.5px);
    content: " ";
    display: block;
    background: #f64747;
}

.payment-iframe {
    width: 100%;
    height: 500px;
}
</style>